import React from 'react';
import images from '../../images/images';


const OffersNewsCarousel = () => {
  return (
    <section className="offers-news-section py-5">
      <div className="container text-center">
        <h2>OFFERS AND NEWS</h2>
        <p className='text-center'>Stay informed about our latest travel offers and important updates to make your next adventure unforgettable!</p>

        <div id="offersNewsCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row">
                <div className="col">
                  <img src={images.offer1} className="d-block w-100" alt="Contest" />
                </div>
                <div className="col">
                  <img src={images.offer2} className="d-block w-100" alt="Chikmagalur Package" />
                </div>
                <div className="col">
                  <img src={images.offer3} className="d-block w-100" alt="Gift Voucher" />
                </div>
                <div className="col">
                  <img src={images.offer4} className="d-block w-100" alt="Kerala Discount" />
                </div>
              </div>
            </div>
            {/* Additional carousel-item slides can be added here */}
          </div>

          <button className="carousel-control-prev" type="button" data-bs-target="#offersNewsCarousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          
          <button className="carousel-control-next" type="button" data-bs-target="#offersNewsCarousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default OffersNewsCarousel;
