import React from "react";
import images from "../../images/images";
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons


function Index() {
  return (
    <>
<section>

        <div className="banner_container01 container-fluid">
          <div className="container">
            <div className="row g-0">
              <div className="col-12 col-md-6">
                <div className="banner_content ps-2 ps-md-5">
                  <h1>ABOUT US</h1>
                  <p>
                  Book One Tour is India’s premier online travel portal, 
                  connecting travelers with expert agents to offer customizable packages 
                  and seamless booking for unforgettable experiences.


                  </p>
                  <div className="banner_btns">
                    {/* <a href="">KNOW MORE</a> */}
                    <a href="https://wa.me/8148818018" target="_blank">Contact Now</a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <img src={images.herobannerimg} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        
        <div className="container text-center my-100">
          <div className="row">
            <div className="col-lg-12">
              <p >
              Book One Tour India’s best online Travel solution portal bringing both the travelers, and trusted & expert travel agents on a common platform and our deals to choose from the wide range of options offered by some of the best travel agents across the country.
              <br /> <br />
              We believe in providing the best kind of service to the best kind of people thus connecting the customers to the travel agents, offering the most suitable package to them. You can sit at the comfort of your doorstep and choose online the most appropriate kind of package that suits you. Our special feature includes customization of packages and services according to the customer's needs, comparison of prices, same packages at different prices and the option of choosing and booking the package online. This will enable our customers to pick the best package in their budget, customize it according to their interests and conveniently book them online.
              <br /> <br />
              To offer you the best memories of your travel we have handcrafted the itineraries and planned in complete accordance with the kind of travel you’re looking for. Plan your honeymoon with some of the best honeymoon packages offered by Book One Tour with inclusions of activities and services that are best enjoyed by the honeymoon and Family Groups.
              <br /> <br />
              Our unique features allow you to customize your package, compare prices on similar packages, and reserve your trip all in one place.
              <br /> <br />
              We handcraft each itinerary to create memorable experiences that match your specific travel desires, whether it’s a romantic honeymoon, a family getaway, or an adventure with friends. With thoughtfully designed packages that include engaging activities and valuable services, we ensure every moment of your journey is truly special. Let Book One Tour transform your travel dreams into reality, with packages and services tailored just for you.
      </p>
            </div>
          </div>
        </div>
</section>
    
    </>
  );
}

export default Index;
