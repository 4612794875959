import React from "react";
import images from "../../images/images";
import { NavLink } from "react-router-dom"; // Ensure the correct path to images // Optional: For additional custom styles if needed

import Itinerary from "../../CommonCompounds/Itinerary/Itinerary";
import OffersNewsCarousel from "../../CommonCompounds/OffersNewsCarousel/OffersNewsCarousel"


function Index() {
  return (
    <>
   <section className="why-bookonetour-section my-80 ">
  <div className="container text-center">
    <div className="row">
      <div className="col-lg-12">
        <h1 >Why Bookonetour For Your Trip</h1>
      </div>
    </div>
    
    <div className="row mt-4">
      <div className="col-lg-4 col-sm-6 col-md-4 ">
        <img src={images.experience} alt="Destinations" className="img-fluid" />
        {/* <h5>24+</h5>
        <p>Destinations</p> */}
      </div>

      <div className="col-lg-4 col-sm-6 col-md-4 ">
        <img src={images.destinations} alt="Destinations" className="img-fluid" />
        {/* <h5>24+</h5>
        <p>Destinations</p> */}
      </div>
      
      <div className="col-lg-4 col-sm-6 col-md-4 ">
        <img src={images.packages} alt="Packages" className="img-fluid" />
        {/* <h5>38+</h5>
        <p>Packages</p> */}
      </div>
    </div>

    <div className="row mt-4">
      <div className="col-lg-4 col-sm-6 col-md-4 ">
        <img src={images.assistance} alt="Assistance" className="img-fluid" />
        {/* <h5>24*7</h5>
        <p>Assistance</p> */}
      </div>
      
      <div className="col-lg-4 col-sm-6 col-md-4 ">
        <img src={images.bookings} alt="Bookings" className="img-fluid"/>
        {/* <h5>500+</h5>
        <p>Bookings</p> */}
      </div>
      
      <div className="col-lg-4 col-sm-6 col-md-4 ">
        <img src={images.happyclients} alt="Happy Clients" className="img-fluid" />
        {/* <h5>1200+</h5>
        <p>Happy Clients</p> */}
      </div>
    </div>
  </div>
</section>
    
    </>
  );
}

export default Index;
