import React from "react";
import Header from "../../CommonCompounds/Header/Header.jsx";
import OotyPackageCarousel from "../../CommonCompounds/OotyPackageCarousel/OotyPackageCarousel.jsx"
import Index from "../../Components/HomePage/HomePage.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Frequently from "../../CommonCompounds/Frequently/Frequently.jsx"
import { Carousel } from "bootstrap";
import Couple from "../../CommonCompounds/Couplepackage/Couplepackage.jsx"
import CarouselPackage from "../../CommonCompounds/CarouselPackage/CarouselPackage.jsx"
import TestimonialCarousel from "../../CommonCompounds/TestimonialCarousel/TestimonialCarousel.jsx"
import OffersNewsCarousel from "../../CommonCompounds/OffersNewsCarousel/OffersNewsCarousel.jsx";
import CarouselPackage02 from "../../CommonCompounds/CarouselPackage02/CarouselPackage02.jsx"

function HomePage(){
    return(
        <>
        <Header />
        <OotyPackageCarousel />
        <CarouselPackage />
        <Couple />
        <CarouselPackage02 />
        <Index />
        <OffersNewsCarousel />
        <TestimonialCarousel />
        <Couple />
        <Frequently />
        <Footer />
        </>
    )
}

export default HomePage;