

import React, { useState, useEffect } from 'react';
import images from '../../images/images'; // Import the images

const ReviewCard = ({ name, location, review, rating, avatar }) => {
  return (
    <div className="col-9 col-md-4 col-lg-3 mb-5">
      <div className="card shadow-sm p-3 bg-white rounded">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <img
              src={avatar}
              alt={`${name}'s avatar`}
              className="avatar rounded-circle"
              style={{ width: '44px', height: '44px' }}
            />
            <div className="ml-3 ">
              <h5 className="card-title">{name}</h5>
              <h6 className="card-subtitle mb-2 text-muted">{location}</h6>
            </div>
          </div>
          <p className="card-text pt-3">{review}</p>
          <div className="d-flex justify-content-between align-items-center rating-container">
            <div>
              {Array.from({ length: rating }).map((_, index) => (
                <span key={index} className="text-warning">
                  ★
                </span>
              ))}
            </div>
            <img
              src={images.Google}
              alt="Google logo"
              style={{ width: '24px', height: 'auto' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const TestimonialCarousel = () => {
  const [chunkSize, setChunkSize] = useState(1); // One card per view
  const [currentIndex, setCurrentIndex] = useState(0);

  const reviews = [
    {
      name: 'shaik mohammad ilias',
      location: 'Chennai, India',
      review:
        'Thank you Book one Tour for organizing such a wonderful trip for our family. We truly enjoyed every moment, and it created lasting memories for us. Everything was well-planned, and we truly  .....',
      rating: 5,
      avatar: images.person1,
    },
    {
      name: 'Ananya S.',
      location: 'Mumbai, India',
      review:
        'Recently, we traveled with Book One Tour. The arrangements were amazing, and it was a wonderful experience for us. All my family members loved the trip. Thanks to the Book One Tour team for the  .....',
      rating: 5,
      avatar: images.person2,
    },
    {
      name: 'Irfhan Abdul',
      location: 'Nungambakkam, Chennai',
      review:
        'Thanks to for the excellent and great value for money Were glad you had a positive experience with One Tour Travels for your Ooty trip, and we re equally proud of our drivers for making your .....',
      rating: 4,
      avatar: images.person3,
    },
    {
      name: 'Ajay.',
      location: 'Mumbai, India',
      review:
        'Thank you for choosing our services for your trip to Ooty. We take great pride in our team of professional drivers who strive to make your travel comfortable and pleasant. Your trust means the .....',
      rating: 5,
      avatar: images.person1,
    },
    {
      name: 'John Ruskin.',
      location: 'Mumbai, India',
      review:
        'I had a fantastic experience with Bookonetour during my trip to Kerala! They made the booking process easy and customized the itinerary to fit my preferences. The highlights included a beautiful .....',
      rating: 5,
      avatar: images.person2,
    },
    {
      name: 'Gayathri Parthiban',
      location: 'Tamilnadu, India',
      review:
        'I had an amazing experience with Book One Tour Chennai to manali.The price was budget friendly and the service was exceptional Highly recommended for a strees free trip. In the given package .....',
      rating: 5,
      avatar: images.person3,
    },
    {
      name: 'Grace.',
      location: 'Bangalore, India',
      review:
        'This makes your business information show up on Maps, Search, and other Google services. To respond to a review, you must have a verified business. Remind customers to leave reviews: Let them .....',
      rating: 4,
      avatar: images.person1,
    },
    {
      name: 'Sailaja.A',
      location: 'Chennai, India',
      review:
        'Recently, we traveled with Book One Tour. The arrangements were amazing, and it was a wonderful experience for us. All my family members loved the trip. Thanks to the Book One Tour team for the .....',
      rating: 5,
      avatar: images.person2,
    },
    {
      name: 'Saravanan Balakrishnan',
      location: 'Tamilnadu, India',
      review:
        'We planned a trip to Yercaud for 3d & 2n from Book One Tour. The trip was well planned and executed throughout all the days and very much enjoyed the climate of Yercaud One good such experience that .....',
      rating: 4,
      avatar: images.person3,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setChunkSize(window.innerWidth < 768 ? 1 : 3); // Adjust chunk size based on screen width
    };

    handleResize(); // Set initial chunk size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const reviewsChunks = reviews.slice(currentIndex, currentIndex + chunkSize);

  const next = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 3) % reviews.length);
  };

  const prev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 3 + reviews.length) % reviews.length
    );
  };

  useEffect(() => {
    const intervalId = setInterval(next, 5000); // Move to next review every 3 seconds
    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [currentIndex]);

  return (
    <div className="container">
      <div className="row text-center pt-5 pb-2">
        <div className="col">
          <h2>See what our clients say</h2>
          <p className="text-center">
            Our clients appreciate the quality of service we provide, and we take pride in ensuring satisfaction.
          </p>
        </div>
      </div>
      <div className="carousel slide">
        <div className="carousel-inner">
          <div className="row justify-content-center">
            {reviewsChunks.map((review, i) => (
              <ReviewCard key={i} {...review} />
            ))}
          </div>
        </div>
        {/* Custom Controls */}
        <button
          className="carousel-control-prev d-none d-md-block" // Hide on mobile
          type="button"
          onClick={prev}
          style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next d-none d-md-block" // Hide on mobile
          type="button"
          onClick={next}
          style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '0' }}
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
